<template>
<div>
    <div id="breadcrumb" class="section">
		<!-- container -->
		<div class="container">
			<!-- row -->
			<div class="row">
				<div class="col-md-12">
					<ul class="breadcrumb-tree">
						<li><a href="/">Home</a></li>
						<li class="active">Blog</li>
					</ul>
				</div>
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
	</div>
	<!-- /BREADCRUMB -->

	<!-- SECTION -->
	<div class="section">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-xs-12" style="text-align: center;padding-bottom: 30px;">
					<h3 class="title">Blog Inpel</h3>
				</div>
				<div v-for="artigo in artigos" :key="artigo.institucionalId" class="col-md-4 col-xs-6" style="text-align: center;padding-bottom: 60px;">
					<div class="product-img">
						<a href="#" @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))">
							<img v-if="artigo.institucionalImg" :src="$Url + artigo.institucionalImg[0].urlImagem" alt="" style="width: 100%;">
						</a>
					</div>
					<a href="#" @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/',''))">
						<p class="product-category" style="font-size: 18px;padding-top: 10px;color: #2B2D42;font-weight: 500;">{{ artigo.nome }}</p>
					</a>
				</div>
			</div>
		</div>
	</div>
	<!-- /SECTION -->
</div>
</template>

<script>
    import moment from 'moment'

    export default {
        components: {
        },
        data() {
            return {
                artigos: [],
                fundotela:'',
            }
        },
        methods: {
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {
            // Conteudo Artigos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/notícias")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        return a.data1 - b.data1;
                    }).reverse();
                });
        },
        mounted() {

        },
    }

</script>

<style scoped>
</style>
<template>
    <div>

        <!-- BREADCRUMB -->
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><a href="index.html">Home</a></li>
                            <li class="active">Caixas de Transmissão</li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->

        <!-- SECTION -->
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <!-- Product main img -->
                    <div class="col-md-5">
                        <div id="product-main-img">
                            <div class="product-preview">
                                <lingallery :iid.sync="currentId" v-if="ImgProduto!=''"
                                    :addons="{ enableLargeView: false, accentColor: '#AA6144' }" :width="450" :height="450"
                                    :items="ImgProduto" >
                                    <template #thumbnail="{ item }">
                                        <img :src="item.src" />
                                    </template>
                                </lingallery>

                            </div>
                        </div>
                        <br>
                        <strong> {{ Texto1 }}</strong>
                    </div>
                    <!-- /Product main img -->

                    <!-- Product details -->
                    <div class="col-md-7">
                        <div class="product-details">
                             <a type="" href="javascript:window.history.go(-1)" style="float: right;color:#000;font-size: 14px;">Voltar</a>
                            <h2 class="product-name">{{ Produto.nomeProduto }}</h2>
                             
                            <div v-if="Dimensoes != ''">
                                <span>DIMENSÕES DA CAIXA</span>
                                <span class="product-available"><a :href="$Url + Dimensoes[0].urlImg" target="_blank"
                                        style="font-size: 13px;color: #D10024;font-weight: 500;">Clique aqui</a></span>
                            </div>
                            <div v-if="Caracteristicas != ''">
                                <span>CARACTERÍSTICAS DA CAIXA</span>
                                <span class="product-available"><a :href="$Url + Caracteristicas[0].urlImg" target="_blank"
                                        style="font-size: 13px;color: #D10024;font-weight: 500;">Clique aqui</a></span>
                            </div>
                            <h2 class="product-name" style="padding-top: 30px;">Aplicações</h2>
                            <p v-html="Produto.resuno"></p>

                            <h2 class="product-name" style="padding-top: 30px;">Descrição</h2>
                            <p v-html="Produto.descricao"></p>
                            <div v-if="MontaPeca == ''" class="add-to-cart" style="padding-top: 30px;text-align: right;">
                                <button @click="CarregaFinal()" class="add-to-cart-btn">
                                    <i class="fa fa-shopping-cart"></i>
                                    Incluir Lista de Orçamento
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- /Product details -->

                    <!-- Product tab -->
                    <div class="col-md-12" v-if="MontaPeca != ''">
                        <div id="product-tab">
                            <!-- product tab nav -->
                            <ul class="tab-nav">
                                <li class="active"><a data-toggle="tab">MONTE A SUA CAIXA</a></li>
                            </ul>
                            <!-- /product tab nav -->
                            <p v-html="Produto.descricao2"></p>
                            <!-- product tab content -->
                            <div class="tab-content">
                                <!-- tab3  -->
                                <div id="tab3" class="tab-pane active">
                                    <div class="row" style="padding: 20px;">
                                        <div class="col-md-9">
                                            <img :src="$Url + CaixaConstrucao[0].urlImg" style="width: 100%;">
                                        </div>
                                        <!-- Rating -->
                                        <div class="col-md-3">
                                            <div class="row" style="padding-bottom: 20px;">
                                                <h4>Tipo e Relação de Transmissão</h4>
                                              <v-select v-model="SelectedTipo" 
                                                   @input="AbreSentido" 
                                                    :options="SelectListTipo"
                                                    @change="onChange"
                                                    single-line
                                                    label="valor" class="style-chooser" style="width:100%;">
                                               </v-select>
                                               <div v-if="SelectedTipo">
                                                        <img :src="SelectedTipo.imgFoto"/><br>
                                                        <span>{{ SelectedTipo.valor }}</span>
                                                    </div>
                                            </div>
                                            <div class="row" style="padding-bottom: 20px;">
                                                <h4>Sentido Giro</h4>
                                                <v-select v-model="SelectedSentido" 
                                                   @input="AbreGiro" 
                                                    :options="SelectListSentido"
                                                    single-line
                                                    label="valor" class="style-chooser" style="width:100%;">
                                               </v-select>
                                            </div>
                                            <div v-show="mostraGiroLivre" class="row" style="padding-bottom: 20px;">
                                                <h4>Giro Livre</h4>
                                                <v-select v-model="SelectedGiro" 
                                                    :options="SelectListLivre"
                                                    single-line
                                                    label="valor" class="style-chooser" style="width:100%;">
                                               </v-select>
                                            </div>
                                            <div class="row" style="padding-bottom: 20px;">
                                                <h4>Eixo X</h4>
                                                <v-select v-model="SelectedEixoX" 
                                                    :options="SelectListEixoX"
                                                    single-line
                                                    label="valor" class="style-chooser" style="width:100%;">
                                               </v-select>
                                            </div>
                                            <div class="row" style="padding-bottom: 20px;">
                                                <h4>Eixo Y</h4>
                                                <v-select v-model="SelectedEixoY" 
                                                    :options="SelectListEixoY"
                                                    single-line
                                                    label="valor" class="style-chooser" style="width:100%;">
                                               </v-select>
                                            </div>
                                            <div class="row" style="padding-bottom: 20px;">
                                                <h4>Posição Trabalho</h4>
                                                <v-select v-model="SelectedPosicao" 
                                                    :options="SelectListPosicao"
                                                    single-line
                                                    label="valor" class="style-chooser" style="width:100%;">
                                               </v-select>
                                            </div>
                                        </div>
                                        <!-- /Rating -->
                                        <div class="col-md-12" style="text-align: center;padding-top: 30px;">
                                            <div class="product-details">
                                                <div class="add-to-cart">
                                                            <button @click="CarregaFinal(SelectedTipo.valor + ' | ' + SelectedSentido.valor + ' | ' + (SelectedGiro.valor === undefined ? '' : 'Giro Livre: ' + SelectedGiro.valor) + ' | Eixo X: ' + SelectedEixoX + ' | Eixo Y: ' + SelectedEixoY + ' | Posição: ' + SelectedPosicao)" class="add-to-cart-btn"><i class="fa fa-shopping-cart"></i>
                                                                Incluir Lista de Orçamento</button>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /tab3  -->
                            </div>
                            <!-- /product tab content  -->
                        </div>
                    </div>
                    <!-- /product tab -->
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>

    </div>
</template>

<script>

import { VMoney } from 'v-money'
import moment from 'moment';
import Lingallery from 'lingallery';

export default {
    metaInfo: {
        bodyScript: [
            { src: '' }
        ],
    },
    components: {
       Lingallery,
    },
    data() {
        return {
            Pagina: this.$route.params.pagina,
            vModal: this.$route.params.modal,
            ProdutoId: this.$route.params.id,
            segmentoProdNivel1Id: this.$route.params.segmento,
            segmentoProdNivel2Id: this.$route.params.idSubsegmento,
            nomeSegmento: '',
            Produto: {},
            ProdutosIncluso: [],
            ProdutoIncluso: {},
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: true
            },
            ImgProduto: [],
            currentId: 0,
            ProdutosAvulso: [],
            ProdutoAvulso: {},
            totalavulsos: 0,
            totalfoto: 0,
            DadosCompra: {
                produtos: [],
                mensagem: [],
                agenda: [],
                foto: [],
                avulsos: [],
                baloes: [],
                informacao: [],
                pagamento: [],
                cupom: [],
            },
            ProdutosInfoAdicional: [],
            Produtoinfo: {},
            Texto: '',
            ListaEntregaFrete: [],
            vValor: 0,
            EntregaFreteId: 0,
            showModal_addProd: false,
            ValorTotal: 0,
            Qtde: 1,
            pais: {},
            listaFrete: {},
            Frete: '',
            numCep: '',
            CepOrigem: '',
            ligacaoProduto: [],
            ListaEtiquetaImg: [],
            mostraPremio: true,
            UrlimgBannerGeral: '',
            liberaValores: false,
            ClientesGeral: [],
            Clientes: [],
            ClientesTabelaPreco: [],
            TabelaPreco: [],
            MostraCompraminia: '',
            Etiquetas: [],
            Arquivos: [],
            CaixaConstrucao: [],
            Dimensoes: [],
            Caracteristicas: [],
            MontaPeca: [],
            SelectListTipo: [],
            SelectListSentido: [],
            SelectListLivre: [
                {
                    valor: 'Sim'
                },
                {
                    valor: 'Não'
                }],
            SelectListEixoX: [],
            SelectListEixoY: [],
            SelectListPosicao: [],
            TipoMontePeca: [],
            LetraMontePeca: [],
            RelacaoMontePeca: [],
            SentidoMontePeca: [],
            SelectedTipo:'',
            SelectedSentido:'',
            SelectedGiro:'',
            SelectedEixoX:'',
            SelectedEixoY:'',
            SelectedPosicao:'',
            mostraGiroLivre:false,
            currentIndex: 0,
            selectedItem: null,
      items: [
        { text: 'Item 1', value: 'item1', image: 'path/to/image1.jpg' },
        { text: 'Item 2', value: 'item2', image: 'path/to/image2.jpg' },
        { text: 'Item 3', value: 'item3', image: 'path/to/image3.jpg' },
      ],
        }
    },
    directives: { money: VMoney },
    methods: {
        MudaTexto(value) {
            this.Texto = value.descricaoInfo;
        },
        currentDateTime(vdate) {
            return moment(vdate).format('LL');
        },
        CarregaFinal(vdetalhes) {
            if (this.Pagina != null) {
                this.$redirect("/" + this.Pagina);
            } else {
                /*  if (parseInt(this.Produto.qtdeMinima, 10) < parseInt(this.Qtde, 10)) {
                     this.$mensagem_normal("Quantidade indisponível.");
                 }
                 else { */
                this.ArmazenaDados('produtos', vdetalhes)
                this.$redirect("/carrinho");
                //}
            }
        },
        ArmazenaDados(item, value) {

            if (item == 'produtos') {
                let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.produtos.push({ produtoId: parseInt(this.ProdutoId, 10), qtde: parseInt(this.Qtde, 10), produto: this.Produto, detalhes: value });
                    localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))

                }
            }
        },
        totalizaavulsos() {
            let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.avulsos != '') {
                    if (dadosArmazenados.avulsos[0].avulso == "Sem Avulsos") {
                        return 0;
                    }
                    else {
                        let recebe = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                        return recebe;
                    }
                }
                else {
                    return 0;
                }

            }
            else {
                return 0;
            }
        },
        CalculaFrete() {
            if (this.numCep == '') {
                this.$mensagem_normal("Por favor preencha o CEP de entrega");
            }
            else {

                this.$http
                    .get(this.$apiUrl + "/entregacorreio")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.CepOrigem = tiu2[0].cep;

                        let InformacaoFrete = {
                            Produtos: [],
                            ProdutosAvulsos: [],
                            CepOrigem: this.CepOrigem,
                            CepDestino: this.numCep,
                            Correios: true,
                            Entregas: true
                        }
                        let _produto = this.Produto;
                        _produto.qtdeMinima = parseInt(this.Qtde, 10);
                        //console.log(_produto)

                        InformacaoFrete.Produtos.push(_produto);

                        //this.ListaAvulsos.forEach((itempa) => {
                        //    InformacaoFrete.ProdutosAvulsos.push(itempa.itemAvulso);
                        //});

                        console.log(InformacaoFrete)

                        this.$http
                            .post(this.$apiUrl + "/EntregaFrete/Calcular", InformacaoFrete)
                            .then(response => {

                                this.ListaEntregaFrete = response.body
                                //console.log("aqui");
                                console.log(this.ListaEntregaFrete)
                                let vrecebe = []
                                this.ListaEntregaFrete.forEach((itemex) => {
                                    //aqui valida compra minima
                                    if (itemex.compraMinima > 0) {
                                        this.MostraCompraminia = "Nas Compras acima de R$ " + parseFloat(itemex.compraMinima).toFixed(2).toString().replace('.', ',') + ", Você ganha frete Grátis!";
                                    }

                                    if (this.subtotal > itemex.compraMinima) {
                                        vrecebe.push(itemex);
                                    }
                                });

                                if (vrecebe != '') {
                                    let _filtraGratis = vrecebe;
                                    let vrecebe2 = []
                                    _filtraGratis.forEach((itemex2) => {
                                        if (itemex2.nome.indexOf("Frete") != -1 || itemex2.nome.indexOf("Miguel") != -1) {
                                            vrecebe2.push(itemex2);
                                        }
                                    });

                                    if (vrecebe2 != '') {
                                        this.ListaEntregaFrete = vrecebe2;
                                        this.MostraCompraminia = "";
                                    }
                                    else {
                                        this.ListaEntregaFrete = _filtraGratis;
                                    }
                                }
                                //console.log(this.ListaEntregaFrete);
                            },
                                error => {
                                    this.$mensagem_erro("CEP Inválido.")
                                    console.log(error)
                                });

                    });
            }

        },
        AbreSentido(value){
            
            this.SelectedSentido = '';
            this.SelectedGiro = '';
            this.mostraGiroLivre = false
            this.SelectedEixoX = '';
            this.SelectedEixoY = '';
            this.SelectedPosicao = '';
            this.SelectListEixoX = [];
            this.SelectListEixoY = [];
            this.SelectListPosicao = [];
            this.SelectListSentido = [];

            if (value != null) {
                let sentidoList = this.MontaPeca.filter(x => x.letra == value.letraId && x.tipo == value.tipoId && x.relacao == value.relacaoId)

                sentidoList.forEach(element => {
                    let sentido = this.SentidoMontePeca.filter(x => x.institucionalId == element.sentido)[0].nome;
                    this.SelectListSentido.push({ letraId: value.letraId, tipoId: value.tipoId, relacaoId: value.relacaoId, sentidoId: element.sentido, valor: "Sentido: " + sentido })
                });
                this.SelectListSentido = this.SelectListSentido.filter((value, index, self) => self.findIndex((item) => item.valor === value.valor) === index);
            }
        },
        AbreGiro(value){
            this.SelectedEixoX = '';
            this.SelectedEixoY = '';
            this.SelectedPosicao = '';
            this.SelectedGiro = '';
            this.mostraGiroLivre = false
            this.SelectListEixoX = [];
            this.SelectListEixoY = [];
            this.SelectListPosicao = [];

            if (value != null) {
                let dadosList = this.MontaPeca.filter(x => x.letra == value.letraId && x.tipo == value.tipoId && x.relacao == value.relacaoId && x.sentido == value.sentidoId)
                this.mostraGiroLivre = dadosList[0].giroLivre
                console.log( 'dadosList')
                console.log( dadosList)
                this.SelectListEixoX = dadosList[0].eixoX.split(",");
                this.SelectListEixoY = dadosList[0].eixoY.split(",");
                this.SelectListPosicao = dadosList[0].posicao.split(",");
            } 
        },
        onChange(event) {
      const value = event.target.value;
      this.SelectedTipo = this.SelectListTipo.find(item => item.valor === value);
    }
    },
    created() {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
        //carrega informações do produto
        this.$http
            .get(this.$apiUrl + "/produto/" + parseInt(this.ProdutoId, 10))
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Produto = tiu2
                //console.log('this.Produto')
                //console.log(this.Produto)
                if (this.Produto.imagens!= ''){
                this.Produto.imagens.sort(function (a, b) {
                    return a.ordem - b.ordem;
                }).forEach(element => {
                    if (element.urlImg != '') {
                        this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                    }
                }
                );
               //console.log('this.ImgProduto')
               //console.log(this.ImgProduto)
            }
            });

        this.$http
            .get(this.$apiUrl + "/produtopremio/produto/" + parseInt(this.ProdutoId, 10))
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Arquivos = tiu2.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
                if (this.Arquivos != null && this.Arquivos != "") {
                    this.CaixaConstrucao = this.Arquivos.filter(x => x.ordem == 10)
                    this.Dimensoes = this.Arquivos.filter(x => x.ordem == 20)
                    this.Caracteristicas = this.Arquivos.filter(x => x.ordem == 30)
                }
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/tipo")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.TipoMontePeca = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });

                this.$http
                    .get(this.$apiUrl + "/institucional/telas/letra")
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.LetraMontePeca = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                            if (a.ordem > b.ordem) {
                                return 1;
                            }
                            if (a.ordem < b.ordem) {
                                return -1;
                            }
                            return 0;
                        });

                        this.$http
                            .get(this.$apiUrl + "/institucional/telas/relaçãotransmissão")
                            .then((res) => res.json())
                            .then((retornoAPI) => {
                                this.RelacaoMontePeca = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                                    if (a.ordem > b.ordem) {
                                        return 1;
                                    }
                                    if (a.ordem < b.ordem) {
                                        return -1;
                                    }
                                    return 0;
                                });

                                this.$http
                                    .get(this.$apiUrl + "/institucional/telas/sentidogiro")
                                    .then((res) => res.json())
                                    .then((retornoAPI) => {
                                        this.SentidoMontePeca = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                                            if (a.ordem > b.ordem) {
                                                return 1;
                                            }
                                            if (a.ordem < b.ordem) {
                                                return -1;
                                            }
                                            return 0;
                                        });
                                        this.$http
                                            .get(this.$apiUrl + "/MontaPeca/Produto/" + parseInt(this.ProdutoId, 10))
                                            .then((res2) => res2.json())
                                            .then((tiu2) => {
                                                this.MontaPeca = tiu2;
                                                this.MontaPeca.forEach(element => {
                                                    let vletra = this.LetraMontePeca.filter(x=> x.institucionalId == element.letra)[0].nome;
                                                    let vtipo = this.TipoMontePeca.filter(y=> y.institucionalId == element.tipo)[0].nome;
                                                    let vrelacao = this.RelacaoMontePeca.filter(w=> w.institucionalId == element.relacao)[0].nome;
                                                    this.SelectListTipo.push({letraId: element.letra,tipoId:element.tipo,relacaoId: element.relacao,valor: "Tipo: " + vtipo + " " + vletra + " - Relação: " + vrelacao, imgFoto: this.$Url + element.urlImg})
                                                });
                                                this.SelectListTipo = this.SelectListTipo.filter((value, index, self) => self.findIndex((item) => item.valor === value.valor) === index).sort(function (a, b) {
                                                    if (a.valor > b.valor) {
                                                        return 1;
                                                    }
                                                    if (a.valor < b.valor) {
                                                        return -1;
                                                    }
                                                    return 0;
                                                });
                                            });
                                    });
                            });
                    });
            });



        //carrega informações de todos os produtos
        this.$http
            .get(this.$apiUrl + "/produtotextogeral")
            .then((res2) => res2.json())
            .then((tiu2) => {
                if (tiu2 != null) {
                    this.UrlimgBannerGeral = tiu2[0].urlImg;
                    this.Texto1 = tiu2[0].texto1;
                }
            });

        // cria localstorage para armazenar informações da compra
        let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))
        }

    },
    mounted() {
    },
    computed: {
        subtotal: function () {
            return this.totalavulsos + (this.Produto.valorAte * parseInt(this.Qtde, 10));
        },
    }
}
</script>

<style>

p {
    margin-top: 0;
    margin-bottom: 0.5rem !important;
}

 .zoomable {
    cursor: zoom-in;
    max-height: 100%;
    overflow: hidden;
  }
  .zoomable img {
    max-width: 100%;
    height: auto;
  }
  .vue-panzoom {
    position: relative;
  }
  .vue-panzoom__zoom {
    position: absolute;
    top: 0;
    left: 0;
  }
  .vue-panzoom__drag-container {
    cursor: move;
  } 
  
input {
    font-size: 20px;
    padding: 6px;
}

input[type=number]::-webkit-inner-spin-button {
    all: unset;
    min-width: 21px;
    min-height: 45px;
    margin: 17px;
    padding: 0px;
    background-image:
        linear-gradient(to top, transparent 0px, transparent 16px, #fff 16px, #fff 26px, transparent 26px, transparent 35px, #000 35px, #000 36px, transparent 36px, transparent 40px),
        linear-gradient(to right, transparent 0px, transparent 10px, #000 10px, #000 11px, transparent 11px, transparent 21px);
    transform: rotate(90deg) scale(0.8, 0.9);
    cursor: pointer;
}

.lingallery_thumbnails_content_elem img {
    border-color: #AA6144 !important;
}

.lingalleryContainer .lingallery .lingallery_spinner {
    position: absolute;
    left: 50%;
    top: calc(50% - 32px);
    display: none !important;
}



</style>
<template>
  <div id="app">

    <NavMenu />
    <div class="altceltop"></div>
    <transition name="fadeIn">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
    
    <Footer />
    <div v-if="LinkWhats!=''" class="whatsapp-fixo">
      <a :href="LinkWhats[0].linkBtn1" target="_blank">
          <img src="/img/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
      </a>
     </div>
  </div>
</template>

<script>

  import NavMenu from './components/site/shared/NavMenu.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
        name: 'App',
        metaInfo: {
            htmlAttrs: {
                lang: 'pt-br'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
            ]
        },
        data() {
            return {
              LinkWhats:[],
            }
          },
    components: {
      NavMenu,
      Footer
    },
    created() {
      this.$http
      .get(this.$apiUrl + "/institucional/telas/infocontato")
      .then((res) => res.json())
      .then((idi) => {
        this.LinkWhats = idi;
      });
    },
    
  }

</script>

<style> 

@import '/css/bootstrap.min.css'; 
@import '/css/slick.css'; 
@import '/css/slick-theme.css'; 
@import '/css/nouislider.min.css'; 
@import '/css/font-awesome.min.css'; 
@import '/css/style.css'; 

/* Carrinho */
@import '/css_cart/carrinho.css';
@import '/css_cart/login.css';
@import '/css_cart/pedido_realizado.css';

/* ---------------- Styles */

/*---------- Animations */
.fadeIn-enter {
  opacity: 0;
}
.fadeIn-enter-active {
  transition: opacity .8s;
}
.fadeIn-enter-to {
opacity: 1;
}
/*---------- End Animations */
ul.lista-sidebar_links {
  padding-left: 0;
}

.set-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none !important;
}

.ajusteFontMenu {
  color:#ffffff !important;
  font-size: 16px;
}
.ajusteMenu {
    padding: 10px 15px 10px 15px;
}

span.subtexto {
  color: #202020;
  font-size: 14px;
  font-weight: 600;
}

.link_decoration {
  text-decoration: underline !important;
  transition: all 2s ease-in;
}
.link_decoration:hover {
  text-decoration: none !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #AA6144 !important;
}

.main_nav_desktop li a.active {
	color: #fff !important;
	font-weight: 500;
}

ul.cat_menu {
  padding-left: 0 !important;
  min-width: 150px;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #000000 !important;
    outline: 0;
   box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}

/********************
Responsive
********************/

/* Smartphones */
@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }
  .product_item {
    left: 0px;
  }

  .altceltop {
    padding-top: 0px;
  }
}

/* Smartphones + tablets */
@media (max-width:991px) {
  .hidden-sm {
    display: none !important;
  }

  .altceltop {
    padding-top: 0px;
  }

}

/* Tablets */
@media (min-width:768px) and (max-width:991px) {
  .hidden-md {
    display: none !important;
  }

  .altceltop {
    padding-top: 110px;
  }

}

/* Netbooks */
@media (min-width:992px) and (max-width:1199px) {
  .hidden-lg {
    display: none !important;
  }
  .altceltop {
    padding-top: 120px;
  }

}

/* Desktop + Notbooks */
@media (min-width:1200px) {
  .hidden-xl {
    display: none !important;
  }
  .altceltop {
    padding-top: 150px;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 0px !important;
}

}

p {
    margin-top: 0;
    margin-bottom: 0.5rem !important;
}

</style>

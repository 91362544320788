<template>
    <div>
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><a href="/">Home</a></li>
                            <li class="active">Departamentos</li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->

        <!-- SECTION -->
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <h3 class="footer-title">Departamentos</h3>
                        <ul class="footer-links" style="padding-top: 20px;font-size: 18px;">
                            <li style="border-bottom: 1px solid #ccc;width: 80%;" v-for="linhaRet in Retorno"
                                :key="linhaRet.institucionalId">
                                <a href="" @click.prevent="carregaArtigo(linhaRet.institucionalId)">
                                    {{ linhaRet.nome }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-9 col-xs-12" style="text-align: center;padding-bottom: 30px;">
                        <span style="float: right;"></span>
                        <h3 class="title">{{ Titulo }}</h3>
                        <p class="product-category" style="font-size: 13px;padding-top: 10px; text-align: justify;"
                            v-html="Texto">
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- /SECTION -->
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            InstitucionalId: this.$route.params.id,
            Retorno: [],
            linhaRet: {},
            Dados: [],
            Titulo: '',
            Texto: '',
        }
    },
    methods: {
        carregaArtigo(value) {
            this.$http
                .get(this.$apiUrl + "/institucional/" + parseInt(value, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Dados = tiu2;
                    if (this.Dados != null) {
                        this.Titulo = this.Dados.nome;
                        this.Texto = this.Dados.descricao1;
                    }
                });
        },
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/infodepartamentos")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2.filter(x => x.visivel);
            });

        if (typeof this.$route.params.id != 'undefined') {
            this.carregaArtigo(this.$route.params.id);
        }

    },
    mounted() {
    },
}
</script>

<style scoped></style>
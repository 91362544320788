<template>
    <div>
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><a href="/">Home</a></li>
                            <li class="active">Sobre a Inpel</li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->

        <!-- SECTION -->
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-6">
                        <h3>{{Titulo}}</h3>
                        <br />
                        <p style="text-align: justify;" v-html="Texto">
                        </p>
                    </div>
                    <div class="col-lg-6">
                        <img :src="imgSobre" style="width: 100%;" />
                    </div>
                </div>
                <div class="row" style="padding-top: 60px;">
                    <div class="col-lg-12" style="text-align: center; padding-bottom: 20px;">
                        <h3>Vídeo Institucional</h3>
                    </div>
                    <div class="col-lg-12" style="text-align: center; padding-bottom: 20px;">
                        <iframe class="ajustetam" :src="linkvideo"
                            title="YouTube video player" style="border:1px solid #ccc;" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
                <div class="row" style="padding-top: 60px;">
                    <div class="col-lg-12" style="text-align: center; padding-bottom: 20px;">
                        <h3>Downloads</h3>
                    </div>
                    <div class="col-lg-6" v-for="itemD in DownloadsSobre" :key="itemD.institucionalId">
                        <a :href="itemD.linkBtn1" target="_blank">
                            <img :src="$Url + itemD.institucionalImg[0].urlImagem" style="width: 100%;" />
                        </a>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /SECTION -->

        <!-- SECTION -->
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row" style="justify-content: center;">
                    <div class="col-md-12 col-xs-12" style="text-align: center;padding-bottom: 30px;">
                        <h3 class="title">Parceiros Inpel</h3>
                    </div>
                    <div class="col-md-12">
                            <div class="row">
                               <carousel autoplay :autoplayTimeout=4000 autoplayHoverPause paginationEnabled :paginationSize=10 :loop="true" style=" height: 140px !important; "
                               :perPageCustom="[[340, 1],[360, 2],[768, 3], [1024,6], [1600,6]]">
                                  <slide v-for="itemP in ParceirosSobre" :key="itemP.institucionalId" style="padding: 10px;">
                                    <div class="product-img">
                                        <img :src="$Url + itemP.institucionalImg[0].urlImagem" :alt="itemP.nome" :title="itemP.nome" style="width: 100%;border:1px solid #d7d7d7;padding: 10px;" />
                                    </div>
                                   </slide>
                                </carousel>
                            </div>
                        </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /SECTION -->
    </div>
</template>

<script>
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';

export default {
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            toposobre: [],
            toposobrenome: '',
            toposobreimg: '',

            Retorno: [],
            Titulo: '',
            Texto: '',
            imgSobre:'',
            linkvideo:'',

            DownloadsSobre: [],
            ParceirosSobre: [],
        }
    },
    methods: {
        currentDateTime(vdate) {
            return moment(vdate).format('DD/MM/YYYY')
        },
    },
    created() {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
        this.$http
            .get(this.$apiUrl + "/institucional/telas/descrição")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;

                if (this.Retorno != null) {
                    this.Titulo = this.Retorno[0].nome;
                    this.Texto = this.Retorno[0].descricao1;
                    this.linkvideo = this.Retorno[0].linkBtn1;
                    if (this.Retorno[0].institucionalImg != '') {
                       this.imgSobre = this.$Url + this.Retorno[0].institucionalImg[0].urlImagem;
                    }
                }

            });

            this.$http
            .get(this.$apiUrl + "/institucional/telas/downloads")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.DownloadsSobre = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });
            });

            this.$http
            .get(this.$apiUrl + "/institucional/telas/parceiros")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.ParceirosSobre = retornoAPI.filter(x => x.visivel).sort(function () {
                    return .5 - Math.random();
                });
            });

    },
    mounted() {

    },
}
</script>

<style scoped>

.ajustetam{
   width: 660px;
   height: 350px;
}

@media (max-width:767px) {
    .ajustetam{
   width: 350px;
   height: 300px;
}
}

</style>
<template>

    <section class="carrinho">

        <section class="main-minha_conta">
            <CheckAcesso />
            <div class="container">
                <div class="row">
                    <div class="col-lg-3" style="background-color: #cccccc;">
                        <ul class="lista-sidebar_links" style="list-style-type: none;padding:10px;">
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusDados" class="texto active">Meus Dados</router-link></li>
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusPedidos" class="texto">Meus Pedidos</router-link></li>
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusArquivos" class="texto">Arquivos</router-link></li>
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusGaleriaFotos" class="texto">Galeria de Fotos</router-link></li>
                            <li style="border-bottom: 1px solid #f9f9f9 ;padding:10px;"><router-link to="/meusVideos" class="texto">V&iacute;deos</router-link></li>

                        </ul>
                    </div>
                    <div class="col-lg-8 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                        <h6 class="subtitulo h5 mb-4">Meus Dados</h6>

                        <!-- Form editar dados -->
                        <form>
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <input v-model="nome" type="text" class="form-control" placeholder="Nome Completo">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <input v-model="telefone" v-mask="'(##) #####-####'" type="text" class="form-control" placeholder="Telefone">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <input v-model="whatsapp" v-mask="'(##) #####-####'" type="text" class="form-control" placeholder="Whatsapp">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <input v-model="email" type="text" class="form-control" placeholder="E-mail">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <input v-model="senha" type="text" class="form-control" placeholder="Senha">
                                </div>
                            </div>
                        </form>

                        <div class="text-right">
                            <button @click="AtualizaDados()" class="btn btn-success">Salvar Dados</button>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'

    export default {
        components: {
            CheckAcesso,
        },
        data() {
            return {
                ClienteId: 0,
                nome: '',
                telefone: '',
                whatsapp: '',
                email: '',
                senha: '',
                senhaatual: '',
                Cliente:[],
            }
        },
        methods: {
            AtualizaDados() {
                if (this.nome == '') {
                    this.$mensagem_normal("Por favor preencha o nome completo");
                } else if (this.whatsapp == '') {
                    this.$mensagem_normal("Por favor preencha o whatsapp");
                } else if (this.email == '') {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                } else {
                    let _cliente =
                    {
                        clienteId: parseInt(this.ClienteId,10),
                        nomeEmpresa: this.nome,
                        email: this.email,
                        whatsapp: this.whatsapp,
                        telefone: this.telefone,
                        tipoCliente: { tipoClienteId: 1 },
                        ativo: true,
                        visivel: true,
                        senha: '',
                    };

                    if (this.senha != '') {
                        _cliente.senha = this.$sha1(this.senha);
                    }
                    else {
                        _cliente.senha = this.senhaatual;
                    }
                    

                    this.$http.put(this.$apiUrl + "/clientes/" + parseInt(this.ClienteId, 10), _cliente).then(
                        (response) => {
                            this.Cliente = response.body;
                            this.$mensagem_normal("Dados atualizados.");
                            this.senha = '';
                            this.$cookie.delete(this.$UsuarioCookie);
                            this.$redirect_reload("/login");
                        });
                }
            },
        },
        created() {
            let usuarioTmp = JSON.parse(this.$cookie.get(this.$UsuarioCookie));
            if (usuarioTmp != null) {
                this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)

                this.$http
                    .get(this.$apiUrl + "/clientes/" + parseInt(this.ClienteId,10))
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Cliente = tiu2;

                        this.nome = this.Cliente.nomeEmpresa
                        this.telefone = this.Cliente.telefone
                        this.whatsapp = this.Cliente.whatsapp
                        this.email = this.Cliente.email
                        this.senha = ''
                        this.senhaatual = this.Cliente.senha
                    });
            }
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
</style>
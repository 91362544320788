<template>
    <div>

        <div class="mb-4 px-md-4">

            <div class="agendamento">

                <div class="row">
                    <div class="col-sm-7 offset-sm-5" style="text-align:center;">
                        <img class="img-fluid" :src="$Url + DadosPagseguro.cadOperadora.urlImg" style="width:200px;height:auto;" alt="">
                    </div>
                </div>
                <div class="row" style="text-align:center;">
                    <div class="col-sm-7 offset-sm-5">
                        <p class="texto-destaque mt-4">Valor Total da compra R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</p>
                        <p class="texto-strong d-inline-block mb-0 mr-2">
                            clique abaixo para efetuar pagamento
                        </p>
                    </div>
                </div>
                <row>
                    <div class="col-sm-7 offset-sm-5 text-center">
                        <button @click="CarregaPagseguro()" class="btn btn-success w-100">Efetuar Pagamento</button>
                    </div>
                </row>
            </div>
        </div>

    </div>
</template>

<script>


    export default {
        components: {

        },
        data() {
            return {
                TotalProdutos: 0,
                DadosPagseguro: '',
                dados: '',
                email: '',
                TokenPagseguro: '',
                CodigoRetPagseguro: '',
            };
        },
        methods: {
            PagamentoEfetuado() {
                //faz upload da imagem
                //carrega dados compra
                //redireciona
            },
            CarregaPagseguro() {
                let retorno = {};
                var urlteste = encodeURIComponent("https://ws.pagseguro.uol.com.br/v2/checkout" +
                            "?email=" + this.email + "&token=" + this.TokenPagseguro + 
                            
                            this.dados
                        );
                        //console.log(urlteste);
                this.$http
                    .post(
                        this.$apiUrl +
                        "/post/pagseguro?url=" + urlteste
                        
                    )
                    .then(
                        (response) => {
                            if (response.ok) {
                                retorno = response.body;
                                let parser = new DOMParser();
                                retorno = parser.parseFromString(retorno, 'text/xml');
                                let val1 = retorno.getElementsByTagName("code")[0].textContent;
                                this.CodigoRetPagseguro = val1;
                                this.load_script();
                            }
                        },
                        (error) => {
                            this.$mensagem_erro(
                                "Problemas na conexão com Pagseguro, por favor tente mais tarde novamente."
                            );
                            console.log(error);
                            //console.log(this.dados)
                        }
                    );
            },
            load_script() {
                //Insira o código de checkout gerado no Passo 1
                var code = this.CodigoRetPagseguro;
                var callback = {
                    success: function (transactionCode) {
                        let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.pagamento = []
                        dadosArmazenados.pagamento.push({
                            FormaPgto: 'Pagseguro - Transaction ' + transactionCode,
                            TipoPgto: 'cartao',
                            CodId: '3',
                            ValorTotal: this.TotalProdutos,
                            FotoComprova: '',
                        });
                        localStorage.setItem(this.$UsuarioDados, JSON.stringify(dadosArmazenados))
                        this.$redirect_reload("/pedidoRealizado");
                    },
                    abort: function () {
                        //Insira os comandos para quando o usuário abandonar a tela de pagamento.
                        this.$mensagem_normal("Não foi possível efetuar o pagamento.");
                    }
                };
                //Chamada do lightbox passando o código de checkout e os comandos para o callback
                var isOpenLightbox = window.PagSeguroLightbox(code, callback);
                // Redireciona o comprador, caso o navegador não tenha suporte ao Lightbox
                if (!isOpenLightbox) {
                    console.log(code)
                    location.href = "https://pagseguro.uol.com.br/v2/checkout/payment.html?code=" + code;
                    console.log("Redirecionamento")
                }
            },
        },
        created() {
            this.$http
                .get(this.$apiUrl + "/configoperadora/2")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.DadosPagseguro = retornoAPI;
                    console.log(this.DadosPagseguro)
                    this.TokenPagseguro = this.DadosPagseguro.tokenOperadora;
                    this.email = this.DadosPagseguro.emailOperadora;
                    
                });
            //this.TokenPagseguro = '0E15E28A5F5446EEA25A655663B47283';
            
            this.dados = '';

            this.dados = '&currency=BRL';

            let dadosArmazenados = localStorage.getItem(this.$UsuarioDados);
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _totalProdutos = 0;
            let _cupom = 0;
            let cont = 1;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _totalProdutos += parseFloat(itemprod.produto.valorAte * itemprod.qtde)
                    this.dados += '&itemId' + cont + '=' + itemprod.produto.produtoId;
                    this.dados += '&itemDescription' + cont + '=' + itemprod.produto.nomeProduto;
                    this.dados += '&itemAmount' + cont + '=' + parseFloat(itemprod.produto.valorAte).toFixed(2).toString();
                    this.dados += '&itemQuantity' + cont + '='+ itemprod.qtde;
                    this.dados += '&itemWeight' + cont + '=300';
                    cont++;
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                        this.dados += '&itemId' + cont + '=' + itemavul.avulso.itemAvulsoId;
                        this.dados += '&itemDescription' + cont + '=' + itemavul.avulso.nomeItemAvulso;
                        this.dados += '&itemAmount' + cont + '=' + parseFloat(itemavul.avulso.valorDe).toFixed(2).toString();
                        this.dados += '&itemQuantity' + cont + '=1';
                        this.dados += '&itemWeight' + cont + '=300';
                        cont++;
                    });
                }
            }

            //if (dadosArmazenados.foto != '') {
            //    if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
            //        dadosArmazenados.foto.forEach((itemfoto) => {
            //            _totalProdutos += parseFloat(itemfoto.valor)
            //            this.dados += '&itemId' + cont + '=999999';
            //            this.dados += '&itemDescription' + cont + '=Envio de Foto junto Cesta';
            //            this.dados += '&itemAmount' + cont + '=' + parseFloat(itemfoto.valor).toFixed(2).toString();
            //            this.dados += '&itemQuantity' + cont + '=1';
            //            this.dados += '&itemWeight' + cont + '=10';
            //            cont++;
            //        });
            //    }
            //}

            if (dadosArmazenados.agenda != '') {
                if (dadosArmazenados.informacao != '') {
                    _totalProdutos += parseFloat(dadosArmazenados.informacao[0].ValorFrete)
                    this.dados += '&itemId' + cont + '=00000001';
                    this.dados += '&itemDescription' + cont + '=Custo da Entrega';
                    this.dados += '&itemAmount' + cont + '=' + parseFloat(dadosArmazenados.informacao[0].ValorFrete).toFixed(2).toString();
                    this.dados += '&itemQuantity' + cont + '=1';
                    this.dados += '&itemWeight' + cont + '=1';
                    cont++;
                }
            }
            this.dados += '&receiveremail=' + this.email
            this.dados += '&enableRecover=false'
            this.dados += '&shippingAddressRequired=false';
            this.dados += '&extraAmount=0.00';
            this.dados += '&redirectURL=' + this.$Url + '/pedidoRealizado';
            this.dados += '&maxUses=1';
            this.dados += '&maxAge=3000';
            console.log(this.dados)

            if (dadosArmazenados.cupom != '') {
                _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10)
            }

            this.TotalProdutos = _totalProdutos - _cupom;
        },
        computed: {
        },
    };
</script>
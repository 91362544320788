<template>
    <div>
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <!-- ASIDE -->
                    <div id="aside" class="col-md-3">
                        <!-- aside Widget -->
                        <div class="aside">
                            <h3 class="aside-title">Categorias</h3>
                            <div class="checkbox-filter">
                                <div class="input-checkbox">
                                    <router-link :to="'/produtos/' + 999999 + '/linha-completa'">
                                        <input type="checkbox" id="category-1">
                                        <label for="category-1" style="font-size: 13px;color: #2B2D42;font-weight: 500;">
                                            <span></span>
                                            LINHA COMPLETA
                                        </label>
                                    </router-link>
                                </div>
                                <div class="input-checkbox" v-for="segmento in MenuSegmentos"
                                    :key="segmento.segmentoProdNivel1Id">
                                    <router-link
                                        :to="'/produtos/' + segmento.segmentoProdNivel1Id + '/' + segmento.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')">
                                        <input type="checkbox" id="category-1">
                                        <label for="category-1" style="font-size: 12px;color: #2B2D42;font-weight: 500;">
                                            <span></span>
                                            {{ segmento.nomeSegmento }}
                                        </label>
                                    </router-link>
                                </div>
                               
                            </div>
                        </div>
                        <!-- /aside Widget -->
                    </div>
                    <!-- /ASIDE -->
                    <!-- STORE -->
                    <div id="store" class="col-md-9">
                        <!-- store top filter -->
                        <div class="store-filter clearfix">
                            <div class="store-sort" style="float: right;">
                                <v-select v-model="Ordemselected" @input="MudaOrdem"
                                    style="position:relative;z-index:99999999;float:right;width:200px;" :options="Ordenar"
                                    :reduce="nome => nome.valor" single-line label="nome" class="style-chooser">
                                </v-select>
                            </div>
                        </div>
                        <!-- /store top filter -->
                        <!-- store products -->
                        <div class="row">
                            <!-- product -->
                            <h3 class="aside-title">Caixas de Transmissão</h3>
                            <div class="col-md-4 col-xs-6" v-for="item in MenuSegmentosProd"
                                :key="item.segmentoProdNivel1Id">
                                <div class="product">
                                    <router-link
                                        :to="'/produtos/' + item.segmentoProdNivel1Id + '/' + item.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')">
                                        <div class="product-img">
                                               <img v-if="item.imagens.length == 2" :src="$Url + item.imagens[0].urlImagem"  @mouseover="imagemReset = item.imagens[0].urlImagem; item.imagens[0].urlImagem = item.imagens[1].urlImagem;show = !show;"  @mouseout="item.imagens[0].urlImagem = imagemReset;show = !show;" style="height: 160px;"/>
                                               <img v-else-if="item.imagens.length >= 1" v-bind:src="$Url + item.imagens[0].urlImagem" alt="" style="height: 160px;">
                                               <img v-else src="/img/indisponivel.jpg" alt="" style="height: 160px;"/> 
                                        </div>
                                    </router-link>
                                    <div class="product-body">
                                        <p class="product-category">Caixas de Transmissão</p>
                                        <h3 class="product-name">
                                            <router-link
                                                :to="'/produtos/' + item.segmentoProdNivel1Id + '/' + item.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')"
                                                style="font-size: 14px;color: #2B2D42;font-weight: 500;">
                                                {{ item.nomeSegmento }}
                                            </router-link>
                                        </h3>
                                    </div>
                                    <div class="add-to-cart">
                                        <button class="add-to-cart-btn"
                                            @click="$redirect_reload('/produtos/' + item.segmentoProdNivel1Id + '/' + item.nomeSegmento.replaceAll(' ', '-').replaceAll('/', ''))">
                                            <i class="fa fa-angle-right"></i> + Detalhes
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- /product -->
                        </div>
                        <!-- /store products -->
                    </div>
                    <!-- /STORE -->
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /SECTION -->
    </div>
</template>

<script>
//import vSelect from 'vue-select';
import { VMoney } from 'v-money'

export default {
    components: {
        // vSelect,
    },
    data() {
        return {
            imagemReset:'',
            imagemFade: {},
            show: true,
            MenuSegmentos: [],
            MenuSegmento: {},
            MenuSegmentosProd: [],
            Ordemselected: 'Ordenar por',
            Ordenar: [
                {
                    nome: "A-Z",
                    valor: 3
                },
                {
                    nome: "Z-A",
                    valor: 4
                }
            ],
            SegselectedNav: 'Escolha Segmento',
            SegMobile: [],
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: true
            },
        }
    },
    directives: { money: VMoney },
    methods: {
        resetHovered() {
            this.imagens.forEach(item => {
                item.hovered = false;
            });
        },
        MudaOrdem() {
            if (this.Ordemselected == 3) {
                this.MenuSegmentosProd.sort(function (a, b) {
                    if (a.nomeSegmento > b.nomeSegmento) {
                        return 1;
                    }
                    if (a.nomeSegmento < b.nomeSegmento) {
                        return -1;
                    }
                    return 0;
                });
            } else if (this.Ordemselected == 4) {
                this.MenuSegmentosProd.sort(function (a, b) {
                    if (a.nomeSegmento > b.nomeSegmento) {
                        return 1;
                    }
                    if (a.nomeSegmento < b.nomeSegmento) {
                        return -1;
                    }
                    return 0;
                }).reverse();
            } else {
                this.MenuSegmentosProd.sort(function (a, b) {
                    if (a.segmentoProdNivel1Id > b.segmentoProdNivel1Id) {
                        return 1;
                    }
                    if (a.segmentoProdNivel1Id < b.segmentoProdNivel1Id) {
                        return -1;
                    }
                    return 0;
                }).reverse();
            }

        },
    },
    created() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        this.$http
            .get(this.$apiUrl + "/SegmentoProdNivel1/")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    if (a.nomeSegmento > b.nomeSegmento) {
                        return 1;
                    }
                    if (a.nomeSegmento < b.nomeSegmento) {
                        return -1;
                    }
                    return 0;
                });
                this.MenuSegmentosProd = this.MenuSegmentos;
            });

    },
}

</script>

<style scoped>
@import '/css/produtos.css';

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


ul {
    padding-left: unset;
}

.form-control-sidebar {
    max-width: 80%;
}


.product_item {
    width: 100%;
    /*        background: #FFFFFF;
        box-shadow: 6px 4px 8px #ccc;*/
    padding-top: 130px;
    padding-bottom: 230px;
    transition: all .2s ease-in;
}

/*        .product_item:hover {
            box-shadow: 10px 10px 8px #ccc;
        }*/


.vfigure {
    position: absolute;
    float: left;
    top: 20px;
    height: 40px;
    z-index: 999;
    text-align: center;
}


/********************
    Responsive
    ********************/

/* Smartphones */
@media (max-width:767px) {
    .product_item {
        left: 0px;
    }

    .shop_sorting {
        float: initial;
    }

    .shop {
        background: #FFFFFF;
        padding-top: 20px;
        padding-bottom: 139px;
    }

    .vfigure {
        position: absolute;
        float: left;
        top: 65px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }
}

/* Tudo, menos desktop */
@media (max-width:1199px) {
    .product_item {
        height: min-content;
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .product_price {
        margin-top: 0px;
    }
}

.img-item {
    /*border-radius: 5px;*/
    /*   box-shadow: 1px 1px 10px #303030; */
    height: 260px;
}

.content {
    padding: 10px 5px 10px 5px;
}

/* Smartphones */
@media (max-width:767px) {
    .content {
        padding: 30px 10px 50px 10px;
    }
}

.vsa-item__trigger:focus,
.vsa-item__trigger:hover {
    outline: none;
    background-color: #5423c6 !important;
    color: var(--vsa-bg-color);
}

.card-header {
    padding: 0.5rem 0rem !important;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
</style>




<template>
    <section class="carrinho">
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><a href="index.html">Home</a></li>
                            <li class="active">Blog</li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->

        <!-- SECTION -->
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-xs-12" style="text-align: center;padding-bottom: 30px;">
                        <h3 class="title">{{ artigo_titulo }}</h3>
                        <small>{{ currentDateTime(artigo_data) }}</small>
                    </div>
                    <div class="col-md-6 col-xs-6" style="text-align: center;padding-bottom: 60px;">
                        <div class="product-img">
                            <img v-if="artigo_imagem != ''" :src="artigo_imagem" alt="" style="width: 100%;">
                            <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="width: 100%;" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-6" style="text-align: center;padding-bottom: 60px;">
                        <p class="product-category" v-html="artigo_texto"
                            style="font-size: 13px;padding-top: 10px; text-align: justify;">
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center mb-5">
                        <div class="view__all view__all--portfolio">
                            <a href="#" @click="$redirect('/blog')" style="font-size: 18px;padding-top: 10px;color: #2B2D42;font-weight: 500;">Voltar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /SECTION -->
    </section>
</template>

<script>
import moment from 'moment'

export default {
    components: {
    },
    data() {
        return {
            artigosLateral: [],
            artigo_titulo: '',
            artigo_imagem: '',
            artigo_texto: '',
            artigo_data: '',
            artigo: {},
        }
    },
    methods: {
        carregaArtigo(idArtigo) {
            this.$http
                .get(this.$apiUrl + "/institucional/" + idArtigo)
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigo = retornoAPI
                    this.artigo_titulo = this.artigo.nome
                    this.artigo_texto = this.artigo.descricao1;
                    this.artigo_data = this.artigo.data1;

                    if (this.artigo.institucionalImg[0] != null) {
                        this.artigo_imagem = this.$Url + this.artigo.institucionalImg[0].urlImagem;
                    }
                    else {
                        this.artigo_imagem = '';
                    }

                });
        },
        currentDateTime(vdate) {
            return moment(vdate).format('DD/MM/YYYY')
        },
    },
    created() {

        if (typeof this.$route.params.id != 'undefined') {
            this.carregaArtigo(this.$route.params.id);
        }
    },
    mounted() {

    },
}

</script>

<style scoped></style>
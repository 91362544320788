<template>

    <section class="carrinho">

        <div id="breadcrumb" class="section">
		<!-- container -->
		<div class="container">
			<!-- row -->
			<div class="row">
				<div class="col-md-12">
					<ul class="breadcrumb-tree">
						<li><a href="/">Home</a></li>
						<li class="active">Fale Conosco</li>
					</ul>
				</div>
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
	</div>
	<!-- /BREADCRUMB -->

		<!-- SECTION -->
		<div class="section">
			<!-- container -->
			<div class="container">
				<!-- row -->
				<div class="row">

					<div class="col-md-7">
						<!-- Billing Details -->
						<div class="billing-details">
							<div class="section-title">
								<h3 class="title" style="padding-bottom: 20px;">{{Tituloform}}</h3>
									<ul class="footer-links">
										<li><a :href="linkwhats" target="_blank"><i class="fa fa-whatsapp"></i>{{Telefoneform}}</a></li>
										<li><a href="#"><i class="fa fa-envelope-o"></i>{{EmailMostra}}</a></li>
									</ul>
							</div>
							<div class="form-group">
								<input class="input" type="text" v-model="Nome" name="Nome" placeholder="Nome">
							</div>
							<div class="form-group">
								<input class="input" type="email" v-model="Email" name="e-mail" placeholder="E-mail">
							</div>
							<div class="form-group">
								<input class="input" type="text" v-model="Telefone" name="telefone" placeholder="Telefone">
							</div>
							<div class="form-group">
								<textarea rows="8" class="input" v-model="Mensagem" placeholder="Mensagem" style="width: 100%;"></textarea>
							</div>
							<a href="#" @click="Enviar()" class="primary-btn order-submit">Enviar</a>
						</div>
					</div>

					<!-- Order Details -->
					<div class="col-md-5 order-details">
						<div class="section-title text-center">
							<h3 class="title mb-3">Onde estamos</h3>
                            <iframe
                                        :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + Textoend"
                                        width="100%" height="350px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                                        tabindex="0">
                                    </iframe>
						</div>
					</div>
					<!-- /Order Details -->
				</div>
				<!-- /row -->
			</div>
			<!-- /container -->
		</div>
		<!-- /SECTION -->
    </section>
</template>
<script>

export default {
    components: {
    },
    data() {
        return {

            toposobre: [],
            toposobrenome: '',
            toposobreimg: '',

            Retorno: [],
            Textoend: '',
            linkwhats: '',
            EmailRecebe: '',
            EmailMostra: '',
            Telefoneform: '',
            Tituloform:'',

            Nome: '',
            Email: '',
            Telefone: '',
            Mensagem: '',
            Resposta: '',
            fundotela: '',
            fundotela2: '',
        }
    },
    methods: {
        Limpar() {
            this.$redirect_reload("/contato");
        },
        Enviar() {
            this.Resposta = "";
            if (this.Email == "") {
                this.$mensagem_normal("Por favor preencha o e-mail");
            } else if (this.Telefone == "") {
                this.$mensagem_normal("Por favor preencha Telefone");
            } else if (this.Mensagem == "") {
                this.$mensagem_normal("Por favor preencha Mensagem");
            } else {
                let tmp = { ToEmail: this.EmailRecebe, Subject: 'Contato do Site', Body: "Nome: " + this.Nome + " <br/> Telefone: " + this.Telefone + " <br/> E-mail: " + this.Email + " <br/> Mensagem:  " + this.Mensagem };
                this.$http
                    .post(
                        this.$apiUrl + "/contato/enviamail/" + 'Inpel', tmp
                    )
                    .then(
                        (response) => {
                            if (response.ok) {
                                this.$mensagem_normal("E-mail enviado com sucesso");
                                this.Nome = '';
                                this.Telefone = '';
                                this.Email = '';
                                this.Mensagem = '';

                            }
                        },
                        (error) => {
                            console.log(error);
                            this.$mensagem_erro(error.bodyText);
                        }
                    );
            }
        }
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/infocontato")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;
                if (this.Retorno != null) {
                    this.Textoend = this.Retorno[0].resumo1;
                    this.EmailRecebe = this.Retorno[0].referencia;
                    this.EmailMostra = this.Retorno[0].campoExtra1;
                    this.Telefoneform = this.Retorno[0].textoBtn1;
                    this.linkwhats = this.Retorno[0].linkBtn1;
                    this.Tituloform = this.Retorno[0].nome;
                }
            });

    },
    mounted() {
    },
}
</script>
<style scoped>

</style>

<template>
    <div>
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><a href="/">Home</a></li>
                            <li class="active">Reclamações, Críticas ou Sugestões</li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row" style="text-align: center;">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <!-- Billing Details -->
                        <div class="billing-details">
                            <div class="section-title">
                                <h3 class="title" style="padding-bottom: 20px;">{{Titulo}}</h3>
                                <p v-html="Texto"></p>
                            </div>
                            <div class="form-group">
                                <span>Deixe sua classificação sobre nós.</span>
                                <div class="estrelas" style="padding-top: 15px;">
                                    <input type="radio" id="cm_star-empty" name="fb" value="" checked />
                                    <label for="cm_star-1"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-1" name="fb" value="1" />
                                    <label for="cm_star-2"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-2" name="fb" value="2" />
                                    <label for="cm_star-3"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-3" name="fb" value="3" />
                                    <label for="cm_star-4"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-4" name="fb" value="4" />
                                    <label for="cm_star-5"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-5" name="fb" value="5" />
                                    <label for="cm_star-6"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-6" name="fb" value="6" />
                                    <label for="cm_star-7"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-7" name="fb" value="7" />
                                    <label for="cm_star-8"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-8" name="fb" value="8" />
                                    <label for="cm_star-9"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-9" name="fb" value="9" />
                                    <label for="cm_star-10"><i class="fa" style="font-size: 30px;"></i></label>
                                    <input v-model="RadioChecked" type="radio" id="cm_star-10" name="fb" value="10" />
                                </div>
                            </div>
                            <div class="form-group">
                                <textarea rows="8" class="input" v-model="Mensagem" placeholder="Mensagem" style="width: 100%;"></textarea>
                            </div>
                            <div class="form-group">
                                <span>Gostaria de receber nosso retorno?</span>
                                <input class="input" type="text" v-model="Nome" name="Nome" placeholder="Nome">
                            </div>
                            <div class="form-group">
                                <input class="input" type="email" v-model="Email" name="e-mail" placeholder="E-mail">
                            </div>
                            <div class="form-group">
                                <input class="input" type="text" v-model="Telefone" name="telefone" placeholder="Telefone">
                            </div>

                            <a href="#" @click="Enviar()" class="primary-btn order-submit">Enviar</a>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <!-- /Order Details -->
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>
</template>

<script>
import moment from 'moment'
export default {
    components: {
    },
    data() {
        return {
            Retorno: [],
            Titulo: '',
            EmailRecebe:'',
            Texto: '',
            Nome: '',
            Email: '',
            Telefone: '',
            Mensagem: '',
            RadioChecked:'',
        }
    },
    methods: {
        currentDateTime(vdate) {
            return moment(vdate).format('DD/MM/YYYY')
        },
        Enviar() {
            if (this.Nome == "") {
                this.$mensagem_normal("Por favor preencha o seu nome");
            } else if (this.Mensagem == "") {
                this.$mensagem_normal("Por favor preencha Mensagem");
            } else {
                let tmp = { ToEmail: this.EmailRecebe, Subject: 'Sugestão do Site', Body: "Estrelas: " + this.RadioChecked +  "<br/> Nome: " + this.Nome + " <br/> Telefone: " + this.Telefone + " <br/> E-mail: " + this.Email + " <br/> Mensagem:  " + this.Mensagem };
                this.$http
                    .post(
                        this.$apiUrl + "/contato/enviamail/" + 'Inpel', tmp
                    )
                    .then(
                        (response) => {
                            if (response.ok) {
                                this.$mensagem_normal("E-mail enviado com sucesso");
                                this.Nome = '';
                                this.Telefone = '';
                                this.Email = '';
                                this.Mensagem = '';
                                this.RadioChecked = '';

                            }
                        },
                        (error) => {
                            console.log(error);
                            this.$mensagem_erro(error.bodyText);
                        }
                    );
            }
        }
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/reclamações")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;

                if (this.Retorno != null) {
                    this.Titulo = this.Retorno[0].nome;
                    this.EmailRecebe = this.Retorno[0].referencia;
                    this.Texto = this.Retorno[0].descricao1;
                }

            });
    },
    mounted() {

    },
}
</script>

<style>
	.estrelas input[type=radio] {
  display: none;
}
.estrelas label i.fa:before {
  content:'\f005';
  color: #FC0;
}
.estrelas input[type=radio]:checked ~ label i.fa:before {
  color: #CCC;
}
</style>